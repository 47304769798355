import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ArrowNarrowRightIcon } from '@heroicons/react/solid';
import { stripHtmlTags } from '../misc/formatHelpers';

export default function SimpleEvent({ offer, onClick }) {
  const { name, event_type_name, summary, slug } = offer.event_base;

  return (
    <div className="event-simple">
      <div className="event-simple__content">
        <div className="event-simple__content__subline">{event_type_name}</div>
        <h2
          className="event-simple__content__headline"
          style={{ marginTop: 0 }}
        >
          {name}
        </h2>
        <span className="event-simple__content__description">
          {stripHtmlTags(summary)}
        </span>
      </div>
      <Link
        to={{ pathname: `/event/${slug}` }}
        className="event-simple__link group"
        onClick={onClick}
      >
        Mehr erfahren{' '}
        <ArrowNarrowRightIcon className="group-hover:translate-x-1" />
      </Link>
    </div>
  );
}

SimpleEvent.propTypes = {
  offer: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};
