import React from 'react';
import PropTypes from 'prop-types';
import SortItem from './SortItem';

export default function SortBar({ sortedBy, setSortedBy }) {
  return (
    <div className="h-full p-4 md:p-6 flex flex-col">
      <div className="w-full flex flex-col sm:flex-row justify-between items-center">
        <span className="text-sm">Sortieren nach…</span>
        <div className="flex space-x-4">
          <SortItem
            sortType="eventBaseName"
            sortedBy={sortedBy}
            setSortedBy={setSortedBy}
          />
          <SortItem
            sortType="date"
            sortedBy={sortedBy}
            setSortedBy={setSortedBy}
          />
        </div>
      </div>
    </div>
  );
}

SortBar.propTypes = {
  sortedBy: PropTypes.object,
  setSortedBy: PropTypes.func.isRequired,
};
