import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Puff } from 'svg-loaders-react';
import _isEmpty from 'lodash/isEmpty';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { locationService } from '../api/locations';
import { eventService } from '../api/events';
import EdmError from '../atoms/EdmError';
import { getEventsGroupedByOffer, sanitizeEvents } from '../misc/dataHelpers';
import EventCard from '../organisms/EventCard';
import GoBack from '../atoms/GoBack';

export default function LocationDetail({ edmConfig }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [location, setLocation] = useState({});
  const [offers, setOffers] = useState({});
  const [mapCoords, setMapCoords] = useState([]);
  const splittedPathname = window.location.pathname.split('/');
  const locationIdFromPathname = splittedPathname[splittedPathname.length - 1];
  const { EDM_BASE_URL } = edmConfig;

  // Fetch location based on id in URL path
  useEffect(() => {
    setIsError(false);
    setIsLoading(true);
    let mounted = true;
    locationService
      .findById({ id: locationIdFromPathname })
      .then((res) => {
        if (mounted) {
          setLocation(res.data);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });

    // eslint-disable-next-line
    return () => (mounted = false);
  }, [locationIdFromPathname]);

  // Fetch events based on location
  useEffect(() => {
    setIsError(false);
    setIsLoading(true);
    const lecParams = {
      expand:
        'event_base,prices,location,dates,event_base.categories,event_base.tags',
      location: locationIdFromPathname,
      page_size: 100,
      serializer_format: 'website_list',
    };
    eventService
      .findUpcoming({
        additionalParams: lecParams,
      })
      .then((res) => {
        const sanitizedEvents = sanitizeEvents(res.data.results);
        const eventsGroupedByOffer = getEventsGroupedByOffer(sanitizedEvents);
        setIsLoading(false);
        setOffers(eventsGroupedByOffer);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [locationIdFromPathname]);

  const { image, name, address } = location;
  const { address_line_1, address_line_2, city, postcode } = address || {};

  const onClickEvent = () =>
    window.parent.postMessage('edm|navigatedToEvent', '*');

  // get latitude and longitude based on address
  useEffect(() => {
    const provider = new OpenStreetMapProvider();

    if (address_line_1 || postcode || city) {
      provider
        .search({ query: `${address_line_1} ${postcode} ${city}` })
        .then((result) => setMapCoords([result[0].y, result[0].x]));
    }
  }, [address_line_1]);

  return (
    <section className="py-4">
      {isError && <EdmError />}

      {isLoading && (
        <div className="flex max-w-3xl lg:max-w-4xl mx-auto">
          <div className="flex items-center justify-center w-full">
            <Puff stroke="#000" className="mx-auto w-20 h-20" />
          </div>
        </div>
      )}

      {!isLoading && !_isEmpty(location) && (
        <Fragment>
          <GoBack />
          <div className="min-w-full prose prose-primary mt-4">
            <div className="flex flex-col sm:flex-row">
              <div className="mb-12 sm:mb-0 sm:w-64">
                <div className="flex flex-col ">
                  <div className="mb-8 flex flex-col">
                    <span className="text-gray-900 font-medium text-2xl">
                      {name}
                    </span>
                    {address && (
                      <span className="text-gray-500">
                        {address_line_2
                          ? `${address_line_1} · ${address_line_2} · ${postcode} ${city}`
                          : `${address_line_1} · ${postcode} ${city}`}
                      </span>
                    )}
                  </div>
                  {image ? (
                    <Fragment>
                      <span className="text-gray-900 font-medium text-lg mb-2">
                        Impressionen
                      </span>
                      <div
                        className="flex-none bg-cover h-64 max-h-64 w-64 rounded shadow"
                        style={{
                          backgroundImage: `url(${EDM_BASE_URL}${image.url})`,
                        }}
                      ></div>
                    </Fragment>
                  ) : (
                    <div className="flex-none sm:w-64"></div>
                  )}
                </div>
                {!_isEmpty(mapCoords) && (
                  <div className="mt-8">
                    <span className="text-gray-900 font-medium text-lg">
                      Anfahrt
                    </span>
                    <MapContainer className="mt-2" center={mapCoords} zoom={16}>
                      <Marker position={mapCoords} />
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      />
                    </MapContainer>
                  </div>
                )}
              </div>

              <div className="sm:ml-20 flex flex-1 flex-col">
                <h1>Veranstaltungen</h1>
                <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                  {!_isEmpty(offers) &&
                    offers.map((offer, index) => (
                      <EventCard
                        key={index}
                        offer={offer}
                        onClick={onClickEvent}
                        edmBaseUrl={EDM_BASE_URL}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </section>
  );
}

LocationDetail.propTypes = {
  edmConfig: PropTypes.object.isRequired,
};
