import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Puff } from 'svg-loaders-react';
import _isEmpty from 'lodash/isEmpty';
import { lecturerService } from '../api/lecturers';
import { eventService } from '../api/events';
import EdmError from '../atoms/EdmError';
import { stripHtmlTags } from '../misc/formatHelpers';
import { getEventsGroupedByOffer, sanitizeEvents } from '../misc/dataHelpers';
import EventCard from '../organisms/EventCard';
import GoBack from '../atoms/GoBack';

export default function LecturerDetail({ edmConfig }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [lecturer, setLecturer] = useState({});
  const [offers, setOffers] = useState({});
  const splittedPathname = window.location.pathname.split('/');
  const lecturerIdFromPathname = splittedPathname[splittedPathname.length - 1];
  const { EDM_BASE_URL } = edmConfig;

  // Fetch lecturer based on id in URL path
  useEffect(() => {
    setIsError(false);
    setIsLoading(true);
    let mounted = true;
    lecturerService
      .findById({ id: lecturerIdFromPathname })
      .then((res) => {
        if (mounted) {
          setLecturer(res.data);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });

    // eslint-disable-next-line
    return () => (mounted = false);
  }, [lecturerIdFromPathname]);

  // Fetch events based on lecturer
  useEffect(() => {
    setIsError(false);
    setIsLoading(true);
    const lecParams = {
      expand:
        'event_base,prices,location,dates,event_base.categories,event_base.tags',
      lecturers: lecturerIdFromPathname,
      page_size: 100,
      serializer_format: 'website_list',
    };
    eventService
      .findUpcoming({
        additionalParams: lecParams,
      })
      .then((res) => {
        const sanitizedEvents = sanitizeEvents(res.data.results);
        const eventsGroupedByOffer = getEventsGroupedByOffer(sanitizedEvents);
        setIsLoading(false);
        setOffers(eventsGroupedByOffer);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [lecturerIdFromPathname]);

  const { image, title, first_name, last_name, description } = lecturer;

  const onClickEvent = () =>
    window.parent.postMessage('edm|navigatedToEvent', '*');

  return (
    <section className="py-4">
      {isError && <EdmError />}

      {isLoading && (
        <div className="flex max-w-3xl lg:max-w-4xl mx-auto">
          <div className="flex items-center justify-center w-full">
            <Puff stroke="#000" className="mx-auto w-20 h-20" />
          </div>
        </div>
      )}

      {!isLoading && !_isEmpty(lecturer) && (
        <Fragment>
          <GoBack />
          <div className="prose prose-primary min-w-full mt-4">
            <div className="flex flex-col sm:flex-row">
              <div className="flex flex-col mb-12 sm:mb-0 sm:w-64">
                {image ? (
                  <div
                    className="flex-none bg-cover h-64 max-h-64 w-64 rounded shadow"
                    style={{
                      backgroundImage: `url(${EDM_BASE_URL}${image.url})`,
                    }}
                  ></div>
                ) : (
                  <div className="flex-none sm:w-64"></div>
                )}
                <span
                  className={`${
                    image ? 'my-4' : 'mb-4'
                  } text-gray-900 font-medium text-2xl`}
                >
                  {title && title} {`${first_name} ${last_name}`}
                </span>
                {description && (
                  <span className="text-gray-500">
                    {stripHtmlTags(description)}
                  </span>
                )}
              </div>

              <div className="sm:ml-20 flex flex-1 flex-col w-full">
                <h1>Veranstaltungen</h1>
                <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                  {!_isEmpty(offers) &&
                    offers.map((offer, index) => (
                      <EventCard
                        key={index}
                        offer={offer}
                        onClick={onClickEvent}
                        edmBaseUrl={EDM_BASE_URL}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </section>
  );
}

LecturerDetail.propTypes = {
  edmConfig: PropTypes.object.isRequired,
};
