import React from 'react';
import { EmojiSadIcon } from '@heroicons/react/outline';
import Error from '../atoms/Error';

export default function Error404() {
  const icon = <EmojiSadIcon className="text-gray-600 w-16 h-16" />;
  const headline = 'Seite nicht gefunden!';
  const parentUrl =
    window.location !== window.parent.location
      ? document.referrer
      : document.location.href;

  const text = (
    <p>
      Leider konnten wir unter der angegebenen Adresse keine Inhalte finden.{' '}
      <a href={parentUrl} target="_parent">
        Besuchen Sie unsere Homepage
      </a>
      , dort werden Sie bestimmt fündig!
    </p>
  );

  return (
    <section className="py-4">
      <div className="flex flex-wrap max-w-3xl lg:max-w-4xl mx-auto prose lg:prose-xl prose-primary">
        <Error icon={icon} headline={headline} text={text} />
      </div>
    </section>
  );
}
