import React from 'react';
import PropTypes from 'prop-types';
import { AcademicCapIcon } from '@heroicons/react/outline';
import IconLabel from './IconLabel';

export default function EventLecturer({
  lecturers,
  size,
  color,
  direction,
  fontWeight,
}) {
  if (!lecturers) return null;

  const label = lecturers.map((lecturer, index) => {
    return (
      <span key={index}>
        {`${lecturer.first_name} ${lecturer.last_name}`}
        {index < lecturers.length - 1 && ', '}
      </span>
    );
  });

  const icon = <AcademicCapIcon />;

  return (
    <IconLabel
      label={label}
      icon={icon}
      color={color}
      size={size}
      direction={direction}
      fontWeight={fontWeight}
    />
  );
}

EventLecturer.propTypes = {
  lecturers: PropTypes.array.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  direction: PropTypes.string,
  fontWeight: PropTypes.string,
};
