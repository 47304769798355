import React, { useState, useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Puff } from 'svg-loaders-react';
import _isEmpty from 'lodash/isEmpty';

import EventDescription from './EventDescription';
/* import EventBanner from './EventBanner'; */
import EventAlternatives from './EventAlternatives';
import EdmError from '../../atoms/EdmError';
import EventHeader from '../../organisms/EventHeader';
import { eventService } from '../../api/events';
import { offerService } from '../../api/offers';
import { getImageUrlFromOffer } from '../../misc/renderHelpers';
import { sanitizeEvents } from '../../misc/dataHelpers';

export default function EventDetail({ edmConfig }) {
  const { hash, pathname } = window.location;
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [offer, setOffer] = useState({});
  const [events, setEvents] = useState([]);
  const [activeEvent, setActiveEvent] = useState({});
  const applyButtonRef = useRef();
  const eventAlternativesRef = useRef();
  const slug = pathname.split('/')[2];
  const eventIdFromHash = hash && parseInt(hash.replace('#', ''), 10);

  const { name, summary, event_type_name, descriptions, categories } = offer;

  const { EDM_BASE_URL, EDM_APP_CONFIG } = edmConfig || {};
  const { settings } = EDM_APP_CONFIG || {};

  const switchActiveEvent = (eventId) => {
    const newEvent = events.filter((event) => {
      return event.id === eventId;
    });

    setActiveEvent(newEvent[0]);
    window.history.pushState(null, null, `#${eventId}`);
  };

  const scrollToAlternatives = () => {
    eventAlternativesRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  // Fetch offer based on slug in URL path
  useEffect(() => {
    setIsError(false);
    setIsLoading(true);
    let mounted = true;
    offerService.findBySlug({ slug }).then((res) => {
      if (mounted) {
        setOffer(res.data);
      }
    });

    // eslint-disable-next-line
    return () => (mounted = false);
  }, [slug]);

  // Fetch events based on offer
  useEffect(() => {
    let mounted = true;
    if (!_isEmpty(offer)) {
      eventService
        .findUpcoming({
          additionalParams: {
            event_base: offer.id,
            expand: 'event_base.contact_person',
          },
        })
        .then((res) => {
          if (mounted) {
            const sanitizedEvents = sanitizeEvents(res.data.results);
            setEvents(sanitizedEvents);
            setActiveEvent(sanitizedEvents[0]);
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
          setIsError(true);
        });
    }

    // eslint-disable-next-line
    return () => (mounted = false);
  }, [offer]);

  // Set activeEvent based on hash
  useEffect(() => {
    if (hash && events.length > 0) {
      switchActiveEvent(eventIdFromHash);
    }
  }, [hash, events]);

  return (
    <section className="py-4">
      {isError && <EdmError />}

      {isLoading && (
        <div className="flex max-w-3xl lg:max-w-4xl mx-auto my-10 sm:my-14 md:my-20">
          <div className="flex items-center justify-center w-full">
            <Puff stroke="#000" className="mx-auto w-20 h-20" />
          </div>
        </div>
      )}

      {!isLoading && !_isEmpty(offer) && activeEvent && (
        <Fragment>
          <EventHeader
            attendeesMax={activeEvent.attendees_max}
            events={events}
            eventId={activeEvent.id}
            eventIdNumber={activeEvent.id_number}
            eventTypeName={event_type_name}
            eventImageUrl={getImageUrlFromOffer(offer)}
            name={name}
            summary={summary}
            categories={categories}
            applyButtonRef={applyButtonRef}
            location={activeEvent.location}
            dates={activeEvent.dates}
            price={activeEvent.lowest_price}
            isEventDetail
            scrollToAlternatives={scrollToAlternatives}
            edmBaseUrl={EDM_BASE_URL}
            settings={settings}
            validBookings={activeEvent.meta.all_valid_bookings_count}
          />

          <div className="flex max-w-3xl lg:max-w-4xl mx-auto">
            <div className="lg:w-full lg:px-8 sm:px-6">
              <div className="text-gray-500 prose lg:prose-xl prose-primary">
                {descriptions.map((description, index) => (
                  <EventDescription
                    key={index}
                    label={description.slug}
                    text={description.text}
                  />
                ))}
                <EventDescription
                  label="Termindetails"
                  dates={activeEvent.dates}
                />
                <EventDescription
                  label="Dozierende"
                  lecturers={activeEvent.lecturers}
                  edmBaseUrl={EDM_BASE_URL}
                />
                <EventDescription
                  label="Ansprechpartner:in"
                  contactPerson={activeEvent.event_base.contact_person}
                  edmBaseUrl={EDM_BASE_URL}
                />
                {events.length > 1 && (
                  <EventAlternatives
                    label="Alternativtermine"
                    events={events}
                    switchActiveEvent={switchActiveEvent}
                    eventAlternativesRef={eventAlternativesRef}
                  />
                )}
              </div>
            </div>
          </div>

          {/* <EventBanner
            eventId={activeEvent.id}
            name={name}
            eventType={event_type_name}
            location={activeEvent.location}
            dates={activeEvent.dates}
            price={activeEvent.lowest_price}
            applyButtonRef={applyButtonRef}
          /> */}
        </Fragment>
      )}
    </section>
  );
}

EventDetail.propTypes = {
  edmConfig: PropTypes.object.isRequired,
};
