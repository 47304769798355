import React from 'react';
import PropTypes from 'prop-types';
import {
  MailIcon,
  PhoneIcon,
  DeviceMobileIcon,
} from '@heroicons/react/outline';
import IconLabel from '../../../atoms/IconLabel';
import { stripHtmlTags } from '../../../misc/formatHelpers';

export default function EventContactPerson({ edmBaseUrl, contactPerson }) {
  const {
    image,
    title,
    first_name,
    last_name,
    main_contact_info,
    description,
  } = contactPerson;

  const { phone, mobile, email } = main_contact_info || {};

  return (
    <div className="flex flex-col sm:flex-row p-8 bg-gray-700 rounded shadow event-contact-person">
      {image && (
        <div
          className="flex-none bg-cover mb-4 sm:mb-0 w-52 h-52 max-h-52 rounded shadow"
          style={{
            backgroundImage: `url(${edmBaseUrl}${image.url})`,
          }}
        ></div>
      )}
      <div className={`${image ? 'sm:ml-8' : ''} flex flex-col flex-shrink`}>
        <span className="mb-2 text-gray-100 font-medium text-2xl">
          {title && title} {`${first_name} ${last_name}`}
        </span>
        {main_contact_info && (
          <div className="flex flex-col sm:flex-row mb-2">
            {email && (
              <div className="flex items-center">
                <IconLabel
                  icon={<MailIcon />}
                  color="light"
                  size="sm"
                  direction="row"
                  label={<a href={`mailto:${email}`}>{email}</a>}
                  fontWeight="medium"
                />
              </div>
            )}
            {phone && (
              <div className="flex items-center mt-2 sm:mt-0 sm:ml-3">
                <IconLabel
                  icon={<PhoneIcon />}
                  size="sm"
                  color="light"
                  direction="row"
                  label={<a href={`tel:${phone}`}>{phone}</a>}
                  fontWeight="medium"
                />
              </div>
            )}
            {mobile && (
              <div className="flex items-center mt-2 sm:mt-0 sm:ml-3">
                <IconLabel
                  icon={<DeviceMobileIcon />}
                  size="sm"
                  color="light"
                  direction="row"
                  label={<a href={`tel:${mobile}`}>{mobile}</a>}
                  fontWeight="medium"
                />
              </div>
            )}
          </div>
        )}
        <span className="text-gray-400">{stripHtmlTags(description)}</span>
      </div>
    </div>
  );
}

EventContactPerson.propTypes = {
  edmBaseUrl: PropTypes.string.isRequired,
  contactPerson: PropTypes.object.isRequired,
};
