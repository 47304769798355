import React from 'react';
import PropTypes from 'prop-types';

export default function Error({ icon, headline, text }) {
  return (
    <div className="w-full px-0 sm:px-4 mb-8 prose lg:prose-xl prose-primary max-w-full">
      <div className="h-full p-6 bg-white rounded shadow-md flex flex-col">
        <div className="flex">
          <div className="flex-grow">{icon}</div>

          <div className="flex-shrink ml-6">
            <h1>{headline}</h1>
            {text}
          </div>
        </div>
      </div>
    </div>
  );
}

Error.propTypes = {
  icon: PropTypes.element,
  headline: PropTypes.string,
  text: PropTypes.element,
};
