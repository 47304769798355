import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CurrencyEuroIcon } from '@heroicons/react/outline';
import {
  formatAmount,
  formatAndSortPrices,
  nonBreakingSpace,
} from '../misc/formatHelpers';
import IconLabel from './IconLabel';

export default function EventPrice({
  lowestPrice,
  prices,
  color,
  size,
  direction,
  fontWeight,
}) {
  if (!prices && lowestPrice === null) return null;

  const sortedPrices = prices && formatAndSortPrices(prices);
  const finalLowestPrice =
    lowestPrice === 0 ? 'kostenfrei' : `ab ${formatAmount(lowestPrice)}€`;

  const label = sortedPrices
    ? sortedPrices.map((price, index) => (
        <Fragment key={index}>
          <span>{`${price.amount}${nonBreakingSpace}€`}</span>{' '}
          <span className="font-normal">
            {`für ${price.price_type_name}`}
            {index < sortedPrices.length - 1 && ', '}
          </span>
        </Fragment>
      ))
    : finalLowestPrice;
  const icon = <CurrencyEuroIcon />;

  return (
    <IconLabel
      label={label}
      icon={icon}
      color={color}
      size={size}
      direction={direction}
      fontWeight={fontWeight}
    />
  );
}

EventPrice.propTypes = {
  lowestPrice: PropTypes.number,
  prices: PropTypes.array,
  color: PropTypes.string,
  size: PropTypes.string,
  direction: PropTypes.string,
  fontWeight: PropTypes.string,
};
