import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ArrowSmLeftIcon } from '@heroicons/react/outline';

export default function GoBack({ label = 'Zurück' }) {
  const navigate = useNavigate();

  return (
    <div
      className="flex group absolute items-center top-0 left-0 cursor-pointer"
      onClick={() => navigate(-1)}
    >
      <ArrowSmLeftIcon className="h-5 w-5 text-primary-500 group-hover:text-primary-700" />
      <span className="ml-2 text-primary-500 group-hover:text-primary-700 text-sm">
        {label}
      </span>
    </div>
  );
}

GoBack.propTypes = {
  label: PropTypes.string,
};
