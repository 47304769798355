import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import GoBack from '../../atoms/GoBack';
import EventLocation from '../../atoms/EventLocation';
import EventDate from '../../atoms/EventDate';
import EventPrice from '../../atoms/EventPrice';
import { stripHtmlTags } from '../../misc/formatHelpers';
import EventAvailability from '../../atoms/EventAvailability';
import EventIdNumber from '../../atoms/EventIdNumber';

export default function EventHeaderEnrollment({
  attendeesMax,
  eventIdNumber,
  eventTypeName,
  eventImageUrl,
  name,
  summary,
  dates,
  price,
  location,
  edmBaseUrl,
  validBookings,
}) {
  return (
    <div className="overflow-hidden flex flex-col md:mr-16 w-full md:w-1/2 md:border md:border-gray-100 md:shadow-lg md:rounded mt-4 md:mt-10 self-start">
      <GoBack />
      <div className="rounded md:rounded-b-none mr-6 w-full hidden sm:block">
        {eventImageUrl && (
          <img
            className="object-cover rounded md:rounded-b-none sm:h-80 md:h-full w-full"
            src={`${edmBaseUrl}${eventImageUrl}`}
            alt=""
          />
        )}
      </div>

      <div className="sm:px-6 pb-10 sm:pb-6 flex-none">
        <div className="pt-4 md:pt-10 mx-auto max-w-7xl">
          <div className="text-left sm:text-center md:text-left">
            <span className="text-sm uppercase font-semibold text-gray-500">
              {eventTypeName}
            </span>
            <h1 className="text-3xl tracking-tight leading-tight font-bold text-gray-900 lg:text-4xl">
              {name}
            </h1>
            <div className="flex flex-col flex-wrap sm:flex-row justify-start mt-1 sm:mt-3">
              <EventAvailability
                attendeesMax={attendeesMax}
                validBookings={validBookings}
              />
            </div>
            <div className="lg:prose-xl prose-primary mt-3 sm:mt-5 lg:mx-0 sm:mx-auto">
              <p className="text-gray-500">{stripHtmlTags(summary)}</p>
            </div>
            <div className="mt-5 sm:mt-8 block sm:flex sm:flex-row items-center justify-center md:block">
              {eventIdNumber && (
                <div className="flex items-center mb-3 sm:mb-0 md:mb-3 mr-0 sm:mr-8 md:mr-0">
                  <EventIdNumber
                    eventIdNumber={eventIdNumber}
                    color="primary"
                    size="md"
                    direction="row"
                    fontWeight="semibold"
                  />
                </div>
              )}
              {location && (
                <div className="flex items-center mb-3 sm:mb-0 md:mb-3 mr-0 sm:mr-8 md:mr-0">
                  <EventLocation
                    location={location}
                    color="primary"
                    size="md"
                    direction="row"
                    fontWeight="semibold"
                  />
                </div>
              )}
              {!_isEmpty(dates) && (
                <div className="flex items-center mb-3 sm:mb-0 md:mb-3 mr-0 sm:mr-8 md:mr-0">
                  <EventDate
                    dates={dates}
                    color="primary"
                    size="md"
                    direction="row"
                    fontWeight="semibold"
                  />
                </div>
              )}
              {price !== null && (
                <div className="flex items-center">
                  <EventPrice
                    lowestPrice={price}
                    color="primary"
                    size="md"
                    direction="row"
                    fontWeight="semibold"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

EventHeaderEnrollment.propTypes = {
  attendeesMax: PropTypes.number,
  eventIdNumber: PropTypes.string,
  eventTypeName: PropTypes.string.isRequired,
  eventImageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  dates: PropTypes.array.isRequired,
  price: PropTypes.number.isRequired,
  location: PropTypes.object,
  edmBaseUrl: PropTypes.string.isRequired,
  validBookings: PropTypes.number,
};
