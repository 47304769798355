import React from 'react';
import PropTypes from 'prop-types';
import EventHeaderDetail from './EventHeaderDetail';
import EventHeaderEnrollment from './EventHeaderEnrollment';

export default function EventHeader({
  attendeesMax,
  slug,
  events,
  isEventDetail,
  eventId,
  eventIdNumber,
  eventTypeName,
  eventImageUrl,
  name,
  categories,
  summary,
  applyButtonRef,
  dates,
  price,
  location,
  scrollToAlternatives,
  edmBaseUrl,
  settings,
  validBookings,
}) {
  const { showIdNumber } = settings?.eventDetail || {};

  return isEventDetail ? (
    <EventHeaderDetail
      attendeesMax={attendeesMax}
      events={events}
      eventId={eventId}
      eventIdNumber={showIdNumber ? eventIdNumber : undefined}
      eventTypeName={eventTypeName}
      eventImageUrl={eventImageUrl}
      name={name}
      categories={categories}
      summary={summary}
      applyButtonRef={applyButtonRef}
      dates={dates}
      price={price}
      location={location}
      scrollToAlternatives={scrollToAlternatives}
      edmBaseUrl={edmBaseUrl}
      settings={settings}
      validBookings={validBookings}
    />
  ) : (
    <EventHeaderEnrollment
      attendeesMax={attendeesMax}
      slug={slug}
      eventIdNumber={showIdNumber ? eventIdNumber : undefined}
      eventTypeName={eventTypeName}
      eventImageUrl={eventImageUrl}
      name={name}
      categories={categories}
      summary={summary}
      dates={dates}
      price={price}
      location={location}
      edmBaseUrl={edmBaseUrl}
      validBookings={validBookings}
    />
  );
}

EventHeader.propTypes = {
  attendeesMax: PropTypes.number,
  slug: PropTypes.string,
  events: PropTypes.array,
  isEventDetail: PropTypes.bool,
  eventId: PropTypes.number.isRequired,
  eventIdNumber: PropTypes.string,
  eventTypeName: PropTypes.string.isRequired,
  eventImageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  categories: PropTypes.array,
  summary: PropTypes.string.isRequired,
  applyButtonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  dates: PropTypes.array.isRequired,
  price: PropTypes.number.isRequired,
  location: PropTypes.object,
  scrollToAlternatives: PropTypes.func,
  edmBaseUrl: PropTypes.string.isRequired,
  settings: PropTypes.object,
  validBookings: PropTypes.number,
};
