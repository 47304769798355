const baseTheme = {
  '--theme-primary-10': '#e0e6f4',
  '--theme-primary-50': '#ccd5ed',
  '--theme-primary-100': '#99acdc',
  '--theme-primary-200': '#4d6dc1',
  '--theme-primary-300': '#3359b9',
  '--theme-primary-400': '#1a44b0',
  '--theme-primary-500': '#002FA7',
  '--theme-primary-600': '#002a96',
  '--theme-primary-700': '#002686',
  '--theme-primary-800': '#002175',
  '--theme-primary-900': '#001c64',
  '--theme-secondary-50': '#f0f8f6',
  '--theme-secondary-100': '#e1f1ed',
  '--theme-secondary-200': '#c2e3db',
  '--theme-secondary-300': '#a4d6ca',
  '--theme-secondary-400': '#85c8b8',
  '--theme-secondary-500': '#67baa6',
  '--theme-secondary-600': '#529585',
  '--theme-secondary-700': '#3e7064',
  '--theme-secondary-800': '#294a42',
  '--theme-secondary-900': '#152521',
  '--theme-font-body':
    'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  '--theme-font-heading':
    'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  '--theme-font-serif':
    'ui-serif, Georgia, Cambria, "Times New Roman", Times, serif',
  '--theme-font-sans':
    'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  '--theme-font-mono':
    'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
};

function applyTheme(theme) {
  const root = document.documentElement;
  Object.keys(theme).forEach((cssVar) => {
    root.style.setProperty(cssVar, theme[cssVar]);
  });
}

export function applyBaseTheme() {
  applyTheme(baseTheme);
}

export function getFontFromConfigAndApplyTheme(cssVarPrefix, fontFamily) {
  const fontTheme = {};

  Object.entries(fontFamily).forEach((family) => {
    const familyType = family[0];
    const value = family[1];
    const fontPrefix = `${cssVarPrefix}-font-${familyType}`;

    fontTheme[fontPrefix] = value;
  });

  applyTheme(fontTheme);
}

export function getColorsFromConfigAndApplyTheme(cssVarPrefix, colors) {
  const colorTheme = {};

  Object.entries(colors).forEach((color) => {
    const colorType = color[0];
    const colorShades = color[1];
    const colorPrefix = `${cssVarPrefix}-${colorType}`;

    Object.entries(colorShades).forEach((shade) => {
      const shadeKey = shade[0];
      const shadeValue = shade[1];
      colorTheme[`${colorPrefix}-${shadeKey}`] = shadeValue;
    });
  });

  applyTheme(colorTheme);
}
