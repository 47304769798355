import { format, parse } from 'date-fns';
import { de } from 'date-fns/locale';
import _sortBy from 'lodash/sortBy';

function parseDay(day, formatString) {
  return parse(day, formatString, new Date());
}

function deFormat(day, givenFormat) {
  return format(day, givenFormat, { locale: de });
}

function formatBetween(fromFormat, toFormat) {
  return (inputDay) => {
    let day = inputDay;
    if (typeof inputDay === 'string') {
      day = parseDay(day, fromFormat);
    }

    return format(day, toFormat);
  };
}

export const dayGermanFormat = formatBetween('yyyy-MM-dd', 'dd.MM.yyy');
export const dayMachineFormat = (date) => format(date, 'yyyy-MM-dd');
export const nonBreakingSpace = '\u00A0';

export function formatAmount(amount) {
  const formatOpts = { minimumFractionDigits: 2 };
  return amount.toLocaleString(undefined, formatOpts);
}

export function formatAndSortPrices(prices) {
  if (!prices || !prices.length) return null;

  if (prices.length > 1) {
    const sortedPrices = _sortBy(prices, [(o) => o.amount]);
    const finalPrices = [];

    sortedPrices.forEach((price) => {
      finalPrices.push({
        amount: formatAmount(price.amount),
        price_type_name: price.price_type_name,
      });
    });

    return finalPrices;
  }

  return `${formatAmount(prices[0].amount)}${nonBreakingSpace}€`;
}

export function formatQueryStringFilterValues(
  filter,
  filterArray,
  filterSeparator
) {
  const filterType = filter.split(filterSeparator)[0];
  const filterValue =
    filterType.indexOf('date') > -1
      ? filter.split(filterSeparator)[1]
      : parseInt(filter.split(filterSeparator)[1], 10);

  return filterArray.push({ type: filterType, id: filterValue });
}

export function formatDateSpan(dates) {
  if (!dates || !dates.length) return null;

  const firstDay = parse(dates[0].day, 'yyyy-MM-dd', new Date());

  if (dates.length > 1) {
    const lastDay = parse(
      dates[dates.length - 1].day,
      'yyyy-MM-dd',
      new Date()
    );
    if (firstDay.getYear() !== lastDay.getYear()) {
      return `${deFormat(firstDay, 'dd. MMM. yyyy')} - ${deFormat(
        lastDay,
        'dd. MMM. yyyy'
      )}`;
    }

    if (firstDay.getMonth() !== lastDay.getMonth()) {
      return `${deFormat(firstDay, 'dd. MMM.')} - ${deFormat(
        lastDay,
        'dd. MMM. yyyy'
      )}`;
    }

    return `${deFormat(firstDay, 'dd.')} - ${deFormat(
      lastDay,
      'dd. MMMM yyyy'
    )}`;
  }

  return deFormat(firstDay, 'dd. MMMM yyyy');
}

export function stripHtmlTags(string) {
  if (!string) return null;
  return string.replace(/<[^>]+>/g, '');
}

export function concatenateExpandParam(defaultExpand, additionalExpand) {
  if (!additionalExpand) return defaultExpand;

  return `${defaultExpand},${additionalExpand}`;
}
