import React from 'react';
import { Link } from 'react-router-dom';
import Chip from './Chip';

export default function EventCategories({ categories, onClick }) {
  if (!categories) return null;

  return categories.map((category, index) => (
    <Link
      to={{
        pathname: '/events',
        search: `?filter={categories=${category.id}}`,
      }}
      key={index}
      className="mr-0 sm:mr-1 mt-2"
      onClick={onClick}
    >
      <Chip label={category.name} color="primary" />
    </Link>
  ));
}
