import React from 'react';
import PropTypes from 'prop-types';
import { LocationMarkerIcon } from '@heroicons/react/outline';
import IconLabel from './IconLabel';

export default function EventLocation({
  locations,
  location,
  color,
  size,
  direction,
  fontWeight,
}) {
  if (!location && (!locations || locations.length === 0)) return null;

  const locationsKeys = locations && Object.keys(locations);
  const locationsLength = locationsKeys && locationsKeys.length;

  const label = location
    ? location.name
    : locationsLength > 1
    ? `an ${locationsLength} Orten verfügbar`
    : locations[locationsKeys[0]].name;

  const icon = <LocationMarkerIcon />;

  return (
    <IconLabel
      label={label}
      icon={icon}
      color={color}
      size={size}
      direction={direction}
      fontWeight={fontWeight}
    />
  );
}

EventLocation.propTypes = {
  locations: PropTypes.array,
  location: PropTypes.object,
  color: PropTypes.string,
  size: PropTypes.string,
  direction: PropTypes.string,
  fontWeight: PropTypes.string,
};
