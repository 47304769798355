import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CalendarIcon } from '@heroicons/react/outline';
import { dayGermanFormat } from '../../../misc/formatHelpers';

export default function EventDates({ dates }) {
  return (
    <Fragment>
      <CalendarIcon className="h7 w-7 text-primary-500" />
      <ul>
        {dates.map((date, index) => (
          <li key={index}>
            <span>{`${dayGermanFormat(date.day)}: `}</span>
            {`${date.start_time} - ${date.end_time} Uhr`}
          </li>
        ))}
      </ul>
    </Fragment>
  );
}

EventDates.propTypes = {
  dates: PropTypes.array.isRequired,
};
