import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Puff } from 'svg-loaders-react';
import EdmError from '../../atoms/EdmError';
import { formatQueryStringFilterValues } from '../../misc/formatHelpers';
import FilterBar from './FilterBar';
import { eventService } from '../../api/events';
import {
  getEventsGroupedByOffer,
  prepareFilterData,
  sanitizeEvents,
} from '../../misc/dataHelpers';
import Event from '../../organisms/Event';
import SimpleEvent from '../../organisms/SimpleEvent';
import { ConditionalWrapper } from '../../misc/renderHelpers';

// swiper bundle styles
import 'swiper/swiper-bundle.min.css';

// swiper core styles
import 'swiper/swiper.min.css';

// modules styles
import 'swiper/components/pagination/pagination.min.css';
import SliderEvent from '../../organisms/SliderEvent';
import SortBar from './SortBar';

SwiperCore.use([Pagination, Autoplay]);

export default function EventList({ edmConfig }) {
  const queryParams = queryString.parse(
    window.location.search.replace('{', '').replace('}', ''),
    {
      arrayFormat: 'comma',
      parseBooleans: true,
    }
  );

  const [offers, setOffers] = useState();
  const [preparedFilterData, setPreparedFilterData] = useState({});
  const [navigateOnParent] = useState(
    () => queryParams && queryParams.navigateOnParent
  );
  const [displayAsSlider] = useState(
    () => queryParams && queryParams.displayAsSlider
  );
  const [offerIds] = useState(() => queryParams && queryParams.offerIds);
  const [eventIds] = useState(() => queryParams && queryParams.eventIds);
  const [contextId] = useState(() => queryParams && queryParams.contextId);
  const [simpleList] = useState(() => queryParams && queryParams.simpleList);
  const [currentFilters, setCurrentFilters] = useState(() => {
    // Apply filters from query string parameters
    const filterArray = [];

    if (queryParams && queryParams.filter) {
      const filterQueryParams = queryParams.filter;
      const filterSeparator = '=';

      if (Array.isArray(filterQueryParams)) {
        filterQueryParams.forEach((filter) => {
          formatQueryStringFilterValues(filter, filterArray, filterSeparator);
        });
      } else {
        formatQueryStringFilterValues(
          filterQueryParams,
          filterArray,
          filterSeparator
        );
      }
    }

    return filterArray;
  });
  const [sortedBy, setSortedBy] = useState({
    type: 'date',
    order: 'ascending',
  });
  const [selected, setSelected] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [limit] = useState(() => queryParams && queryParams.limit);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { PARENT_URL, EDM_BASE_URL, EDM_APP_CONFIG } = edmConfig || {};
  const { settings } = EDM_APP_CONFIG || {};

  const onClick = () => window.parent.postMessage('edm|navigatedToEvent', '*');

  const addFilter = (filterType, filterValue) => {
    if (filterValue) {
      setCurrentFilters([
        ...currentFilters.filter((filter) => filter.type !== filterType),
        { type: filterType, id: filterValue },
      ]);
    } else {
      setCurrentFilters([
        ...currentFilters.filter((filter) => filter.type !== filterType),
      ]);
    }
  };

  const resetFilters = () => {
    setCurrentFilters([]);
    setSelected(null);
    setStartDate(null);
    setEndDate(null);
  };

  const isOfferFiltered = (offer) =>
    currentFilters &&
    currentFilters.every((filter) => {
      if (filter.type === 'categories') {
        return offer.event_base.categories.some(
          (category) => category.id === filter.id
        );
      }

      if (filter.type === 'locations') {
        return offer.locations.some((location) => location.id === filter.id);
      }

      if (filter.type === 'event_types') {
        return offer.event_base.event_type === filter.id;
      }

      if (filter.type === 'date-start') {
        return offer.events.some((event) =>
          event.dates.every((date) => date.day > filter.id)
        );
      }

      if (filter.type === 'date-end') {
        return offer.events.some((event) =>
          event.dates.every((date) => date.day < filter.id)
        );
      }

      return false;
    });

  useEffect(() => {
    setIsError(false);
    setIsLoading(true);

    const reqParams = {
      page_size: parseInt(limit, 10) + 15 || 100,
      serializer_format: 'website_list',
      event_base: offerIds,
      id: eventIds,
      event_base__context: contextId,
    };

    eventService
      .findUpcoming({
        additionalParams: reqParams,
      })
      .then((res) => {
        const sanitizedEvents = sanitizeEvents(res.data.results);
        const eventsGroupedByOffer = getEventsGroupedByOffer(sanitizedEvents);
        setPreparedFilterData(prepareFilterData(eventsGroupedByOffer));
        setIsLoading(false);
        setOffers(eventsGroupedByOffer);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  useEffect(() => {
    if (currentFilters) {
      currentFilters.forEach((filter) => {
        if (filter.type === 'date-start') {
          setStartDate(new Date(filter.id));
        }

        if (filter.type === 'date-end') {
          setEndDate(new Date(filter.id));
        }
      });
    }
  }, [currentFilters]);

  return !isLoading && offers && displayAsSlider ? (
    <Swiper
      slidesPerView={1}
      spaceBetween={10}
      loop={true}
      pagination={{
        type: 'bullets',
      }}
      autoplay={{ delay: 5000, pauseOnMouseEnter: true }}
    >
      {offers.map((offer, index) => {
        return (
          <SwiperSlide key={index}>
            <SliderEvent
              offer={offer}
              edmBaseUrl={EDM_BASE_URL}
              parentUrl={navigateOnParent ? PARENT_URL : ''}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  ) : (
    <section className="py-4">
      <div className="flex flex-wrap max-w-3xl lg:max-w-4xl mx-auto">
        {isError && <EdmError />}

        {isLoading && (
          <div className="flex items-center justify-center w-full">
            <Puff stroke="#000" className="mx-auto w-20 h-20" />
          </div>
        )}

        {!isLoading && !settings?.eventList?.hideFilter && !displayAsSlider && (
          <div className="w-full px-0 sm:px-4">
            <FilterBar
              filterData={preparedFilterData}
              addFilter={addFilter}
              currentFilters={currentFilters}
              resetFilters={resetFilters}
              selected={selected}
              setSelected={setSelected}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </div>
        )}

        {!isLoading && !settings?.eventList?.hideSorting && !displayAsSlider && (
          <div className="w-full px-0 sm:px-4 mb-12">
            <SortBar sortedBy={sortedBy} setSortedBy={setSortedBy} />
          </div>
        )}

        {!isLoading && offers && !displayAsSlider && (
          <ConditionalWrapper
            condition={simpleList}
            wrapper={(children) => (
              <div className="grid sm:grid-cols-2">{children}</div>
            )}
          >
            {offers
              .filter((offer) => isOfferFiltered(offer))
              .sort((a, b) => {
                const { type, order } = sortedBy;

                if (type === 'eventBaseName') {
                  if (order === 'descending')
                    return a.event_base.name < b.event_base.name ? 1 : -1;

                  if (order === 'ascending')
                    return a.event_base.name > b.event_base.name ? 1 : -1;
                }

                if (type === 'date') {
                  if (order === 'descending')
                    return a.first_day < b.first_day ? 1 : -1;

                  if (order === 'ascending')
                    return a.first_day > b.first_day ? 1 : -1;
                }

                return 0;
              })
              .map((offer, index) => {
                return (
                  <Fragment key={index}>
                    {simpleList ? (
                      <SimpleEvent
                        offer={offer}
                        onClick={onClick}
                        parentUrl={navigateOnParent ? PARENT_URL : ''}
                      />
                    ) : (
                      <Event
                        offer={offer}
                        hidePrice={settings?.eventList?.hidePrice}
                        parentUrl={navigateOnParent ? PARENT_URL : ''}
                        onClick={onClick}
                      />
                    )}
                  </Fragment>
                );
              })}
          </ConditionalWrapper>
        )}
      </div>
    </section>
  );
}

EventList.propTypes = {
  edmConfig: PropTypes.object.isRequired,
};
