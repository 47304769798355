import axios from 'axios';
import queryString from 'query-string';
import {
  concatenateExpandParam,
  dayMachineFormat,
} from '../misc/formatHelpers';

const defaultParams = {
  expand: '~all',
  first_day__gte: dayMachineFormat(Date.now()),
  is_public: true,
  status: ['OFFERED', 'TAKES_PLACE'],
};

const findUpcoming = ({ additionalParams }) =>
  axios.request({
    url: 'events/',
    method: 'get',
    params: {
      ...defaultParams,
      ...additionalParams,
      expand: concatenateExpandParam(
        defaultParams.expand,
        additionalParams && additionalParams.expand
      ),
    },
    paramsSerializer: (params) =>
      queryString.stringify(params, { arrayFormat: 'none' }),
  });

const findById = ({ eventId, additionalParams, onlyUpcoming = true }) =>
  axios.request({
    url: `events/${eventId}/`,
    method: 'get',
    params: {
      ...defaultParams,
      ...additionalParams,
      expand: concatenateExpandParam(
        defaultParams.expand,
        additionalParams && additionalParams.expand
      ),
      first_day__gte: !onlyUpcoming ? null : defaultParams.first_day__gte,
    },
  });

export const eventService = {
  findUpcoming,
  findById,
};
