import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from './Button';

export default function LinkedButton({
  to,
  target,
  label,
  customRef,
  variant,
  fullWidth,
  icon,
  size,
  onClick,
}) {
  return (
    <Link to={to} target={target}>
      <Button
        label={label}
        customRef={customRef}
        fullWidth={fullWidth}
        variant={variant}
        icon={icon}
        size={size}
        onClick={onClick}
      />
    </Link>
  );
}

LinkedButton.propTypes = {
  to: PropTypes.object.isRequired,
  label: PropTypes.string,
  target: PropTypes.string,
  customRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
};
