import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import InitApp from './InitApp';
import './js/edm-registration-form';
import './index.css';
import './styles/main.scss';
import ScrollToTop from './helpers/ScrollToTop';

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <InitApp />
  </BrowserRouter>,
  document.getElementById('root')
);
