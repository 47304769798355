import React from 'react';
import PropTypes from 'prop-types';
import List from '../../../organisms/List';
import EventDates from './EventDates';
import EventContactPerson from './EventContactPerson';

function EventText({ text }) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    ></div>
  );
}

export default function EventDescription({
  label,
  text,
  dates,
  lecturers,
  contactPerson,
  edmBaseUrl,
}) {
  if (!text && !dates && !lecturers && !contactPerson) return null;

  return (
    <div className="py-2">
      <h2 className="font-heading text-gray-900 tracking-wider uppercase">
        {label}
      </h2>
      {text && <EventText text={text} />}
      {dates && <EventDates dates={dates} />}
      {lecturers && <List lecturers={lecturers} edmBaseUrl={edmBaseUrl} />}
      {contactPerson && (
        <EventContactPerson
          contactPerson={contactPerson}
          edmBaseUrl={edmBaseUrl}
        />
      )}
    </div>
  );
}

EventText.propTypes = {
  text: PropTypes.string.isRequired,
};

EventDescription.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string,
  dates: PropTypes.array,
  lecturers: PropTypes.array,
  contactPerson: PropTypes.object,
  edmBaseUrl: PropTypes.string,
};
