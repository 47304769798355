import React from 'react';
import PropTypes from 'prop-types';
import { HashtagIcon } from '@heroicons/react/outline';
import IconLabel from './IconLabel';

export default function EventIdNumber({
  eventIdNumber,
  color,
  size,
  direction,
  fontWeight,
}) {
  if (!eventIdNumber) return null;

  const icon = <HashtagIcon />;

  return (
    <IconLabel
      label={eventIdNumber}
      icon={icon}
      color={color}
      size={size}
      direction={direction}
      fontWeight={fontWeight}
    />
  );
}

EventIdNumber.propTypes = {
  eventIdNumber: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  direction: PropTypes.string,
  fontWeight: PropTypes.string,
};
