import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import Error from './Error';

export default function EdmError() {
  const icon = <ExclamationCircleIcon className="text-red-400 w-16 h-16" />;
  const headline = 'Oh nein!';
  const text = (
    <p>
      Leider ist bei der Abfrage der Daten ein Fehler aufgetreten. Bitte
      versuchen Sie es in wenigen Momenten erneut. Sollte das Problem bestehen
      bleiben, schreiben Sie uns gerne eine E-Mail an{' '}
      <a
        href="mailto:contact@priorist.com"
        className="underline text-primary-500"
      >
        contact@priorist.com
      </a>
      .
    </p>
  );

  return <Error icon={icon} text={text} headline={headline} />;
}
