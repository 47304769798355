import React from 'react';
import PropTypes from 'prop-types';
import { ArrowSmDownIcon } from '@heroicons/react/outline';
import GoBack from '../../atoms/GoBack';
import EventCategories from '../../atoms/EventCategories';
import EventLocation from '../../atoms/EventLocation';
import EventDate from '../../atoms/EventDate';
import EventPrice from '../../atoms/EventPrice';
import LinkedButton from '../../atoms/LinkedButton';
import Button from '../../atoms/Button';
import { stripHtmlTags } from '../../misc/formatHelpers';
import EventIdNumber from '../../atoms/EventIdNumber';
import EventAvailability from '../../atoms/EventAvailability';

export default function EventHeaderDetail({
  attendeesMax,
  events,
  eventId,
  eventIdNumber,
  eventTypeName,
  eventImageUrl,
  name,
  categories,
  summary,
  applyButtonRef,
  dates,
  price,
  location,
  scrollToAlternatives,
  edmBaseUrl,
  validBookings,
}) {
  const onClick = () =>
    window.parent.postMessage('edm|navigatedToEventList', '*');

  return (
    <div className="overflow-hidden flex flex-col lg:flex-row-reverse">
      <GoBack />
      <div className="w-full pt-4 md:pt-10 flex-1">
        {eventImageUrl && (
          <img
            className="object-cover rounded lg:rounded-tl-none lg:rounded-bl-none sm:h-96 lg:h-112 w-full"
            src={`${edmBaseUrl}${eventImageUrl}`}
            alt=""
          />
        )}
      </div>
      <div className="pb-6 lg:max-w-2xl lg:w-3/5 xl:w-full flex-none">
        <div className="pt-4 md:pt-10 mx-auto max-w-7xl">
          <div className="sm:text-center lg:text-left">
            <span className="text-sm uppercase font-semibold text-gray-500">
              {eventTypeName}
            </span>
            <h1 className="text-3xl tracking-tight leading-tight font-bold text-gray-900 sm:text-4xl md:text-5xl">
              {name}
            </h1>
            <div className="flex flex-col flex-wrap sm:flex-row sm:justify-center lg:justify-start mt-1 sm:mt-3">
              <EventCategories categories={categories} onClick={onClick} />
            </div>
            <div className="flex flex-col flex-wrap sm:flex-row sm:justify-center lg:justify-start mt-1 sm:mt-3">
              <EventAvailability
                attendeesMax={attendeesMax}
                validBookings={validBookings}
              />
            </div>
            <div className="mt-3 prose lg:prose-xl prose-primary sm:mt-5 sm:pr-8">
              <p className="text-gray-500">{stripHtmlTags(summary)}</p>
            </div>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              {eventIdNumber && (
                <div className="flex items-center mb-3 sm:mb-0 sm:mr-8">
                  <EventIdNumber
                    eventIdNumber={eventIdNumber}
                    color="primary"
                    size="md"
                    direction="row"
                    fontWeight="semibold"
                  />
                </div>
              )}
              {location && (
                <div className="flex items-center mb-3 sm:mb-0 sm:mr-8">
                  <EventLocation
                    location={location}
                    color="primary"
                    size="md"
                    direction="row"
                    fontWeight="semibold"
                  />
                </div>
              )}
              {dates && (
                <div className="flex items-center mb-3 sm:mb-0 sm:mr-8">
                  <EventDate
                    dates={dates}
                    color="primary"
                    size="md"
                    direction="row"
                    fontWeight="semibold"
                  />
                </div>
              )}
              {price !== null && (
                <div className="flex items-center">
                  <EventPrice
                    lowestPrice={price}
                    color="primary"
                    size="md"
                    direction="row"
                    fontWeight="semibold"
                  />
                </div>
              )}
            </div>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <LinkedButton
                to={{ pathname: `/enrollment/${eventId}` }}
                label="Anmelden"
                variant="solid"
                customRef={applyButtonRef}
              />
              {events && events.length > 1 && (
                <div
                  className="ml-0 sm:ml-4 mt-4 sm:mt-0 group"
                  onClick={scrollToAlternatives}
                >
                  <Button
                    label="Alternativtermine"
                    icon={
                      <ArrowSmDownIcon className="ml-2 h-5 w-5 text-primary-500 group-hover:text-primary-700" />
                    }
                    variant="outlined"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

EventHeaderDetail.propTypes = {
  attendeesMax: PropTypes.number,
  events: PropTypes.array,
  eventId: PropTypes.number.isRequired,
  eventIdNumber: PropTypes.string,
  eventTypeName: PropTypes.string.isRequired,
  eventImageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  categories: PropTypes.array,
  summary: PropTypes.string.isRequired,
  applyButtonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  dates: PropTypes.array.isRequired,
  price: PropTypes.number.isRequired,
  location: PropTypes.object,
  scrollToAlternatives: PropTypes.func,
  edmBaseUrl: PropTypes.string.isRequired,
  validBookings: PropTypes.number,
};
