import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import EventDate from '../atoms/EventDate';
import EventLocation from '../atoms/EventLocation';
import EventPrice from '../atoms/EventPrice';
import { stripHtmlTags } from '../misc/formatHelpers';

export default function Event({ offer, hidePrice, onClick, parentUrl }) {
  const { id, name, event_type_name, summary, slug } = offer.event_base;
  const { lowest_price, events, first_day, locations } = offer;

  const finalDestination = parentUrl
    ? `${parentUrl}/event?slug=${slug}`
    : `/event/${slug}`;

  return (
    <Link
      key={id}
      to={{ pathname: finalDestination }}
      target={parentUrl ? '_top' : '_self'}
      className="event group"
      onClick={onClick}
    >
      <div className="event__prose-wrapper">
        <div className="event__content-wrapper group-hover:scale-102 group-hover:border-primary-500">
          <div className="event__content">
            <div className="pb-4">
              <div className="event__content__subline">{event_type_name}</div>
              <h2 className="event__content__headline group-hover:text-primary-500">
                {name}
              </h2>
              <span className="event__content__description">
                {stripHtmlTags(summary)}
              </span>
            </div>
            <div className="event__data">
              <div className="event__data__item">
                <EventDate
                  events={events}
                  firstDay={first_day}
                  fontWeight="semibold"
                />
              </div>
              {!hidePrice && (
                <div className="event__data__item">
                  <EventPrice
                    lowestPrice={lowest_price}
                    fontWeight="semibold"
                  />
                </div>
              )}
              <div className="event__data__item">
                <EventLocation locations={locations} fontWeight="semibold" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

Event.propTypes = {
  offer: PropTypes.object.isRequired,
  hidePrice: PropTypes.bool,
  onClick: PropTypes.func,
  parentUrl: PropTypes.string,
};
