import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Listbox, Transition } from '@headlessui/react';
import { SelectorIcon, CheckIcon } from '@heroicons/react/solid';

export default function FilterSelect({
  filterType,
  filterData,
  addFilter,
  currentFilters,
  selected,
  setSelected,
}) {
  if (!currentFilters) return null;

  const isThisFilterSet = currentFilters.some(
    (filter) => filter.type === filterType
  );

  const getSelectedValue = () => {
    const setFilter = filterData.find((data) =>
      currentFilters.some((filter) => filter.id === data.id)
    );

    return setFilter;
  };

  return (
    <Listbox
      as="div"
      className="relative inline-block w-full sm:w-1/3 py-2 sm:px-2"
      value={selected}
      onChange={setSelected}
    >
      {({ open }) => (
        <div className="relative">
          <Listbox.Button
            className={`${
              isThisFilterSet
                ? 'bg-primary-500 text-gray-50 border-transparent '
                : 'bg-white border-gray-300'
            } relative group w-full py-2 pl-3 pr-10 text-left rounded-md shadow-sm border focus-visible:border-primary-500 cursor-pointer transition duration-300 ease-out focus:outline-none`}
          >
            <span className="block truncate font-medium">
              {isThisFilterSet && getSelectedValue()
                ? getSelectedValue().name
                : filterData[0].name}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              {isThisFilterSet ? (
                <CheckIcon
                  className="w-5 h-5 ml-2 text-gray-50"
                  aria-hidden="true"
                />
              ) : (
                <SelectorIcon
                  className="w-5 h-5 ml-2 opacity-50 group-hover:opacity-100 text-gray-500"
                  aria-hidden="true"
                />
              )}
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              static
              className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
            >
              {filterData.map((data, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `${active ? 'text-gray-50 bg-primary-500' : 'text-gray-900'}
                          cursor-pointer select-none relative py-2 px-3 group`
                  }
                  value={data}
                >
                  {/* eslint-disable-next-line */}
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${
                          selected ? 'font-medium' : 'font-normal'
                        } block group-hover:text-gray-50 group-hover:font-semibold`}
                        onClick={() => addFilter(filterType, data.id)}
                      >
                        {data.name}
                      </span>
                      {selected ? (
                        <span
                          className={`${
                            selected
                              ? active
                                ? 'text-gray-50'
                                : 'text-primary-500'
                              : 'text-primary-500'
                          }
                                absolute inset-y-0 right-2 flex items-center pl-3`}
                        >
                          <CheckIcon
                            className="w-5 h-5 group-hover:text-gray-50"
                            aria-hidden="true"
                          />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}

FilterSelect.propTypes = {
  filterType: PropTypes.string.isRequired,
  filterData: PropTypes.array.isRequired,
  addFilter: PropTypes.func.isRequired,
  currentFilters: PropTypes.array.isRequired,
  selected: PropTypes.object,
  setSelected: PropTypes.func.isRequired,
};
