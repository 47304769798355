import axios from 'axios';
import { concatenateExpandParam } from '../misc/formatHelpers';

const defaultParams = {
  expand: '~all,events.location,events.lecturers',
};

const findBySlug = ({ slug, additionalParams }) =>
  axios.request({
    url: `event_bases/${slug}/`,
    method: 'get',
    params: {
      ...defaultParams,
      ...additionalParams,
      expand: concatenateExpandParam(
        defaultParams.expand,
        additionalParams && additionalParams.expand
      ),
    },
  });

const findById = ({ id, additionalParams }) =>
  axios.request({
    url: `event_bases/${id}/`,
    method: 'get',
    params: {
      ...defaultParams,
      ...additionalParams,
      expand: concatenateExpandParam(
        defaultParams.expand,
        additionalParams && additionalParams.expand
      ),
    },
  });

export const offerService = {
  findBySlug,
  findById,
};
