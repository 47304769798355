const config = {
  settings: {
    mode: 'wizard',
    enableDebug: false,
    showProgressBar: false,
    priceTypeSelect: true,
    edm: {
      baseURL: 'https://edm.priorist.com',
      apiPath: '/api/v1',
      prices: [
        {
          default: true,
          reduced: false,
          type: 'Nicht-Mitglieder',
          label: '',
        },
        {
          default: false,
          reduced: true,
          type: 'Mitglieder',
          label: '',
        },
      ],
      showPriceOnlyForNonPrivate: false,
    },
    newsletter: {
      baseURL: '/?type=20',
      default: {
        listId: 49,
        doiTemplateId: 46,
        label: 'Ich möchte den Newsletter der Paritätischen Akademien erhalten',
        description:
          'Abonnieren Sie den Newsletter der Paritätischen Akademie Berlin und Paritätischen Akademie Süd. Darin erhalten Sie regelmäßig Informationen über unsere Veranstaltungen und Weiterbildungen und erfahren direkt, wenn neue Themen in das Programm aufgenommen werden.',
      },
    },
    behaviour: {
      fadeInTimeout: 500,
      generateIcsFile: true,
      showFileSize: true,
      scrollToNextStep: true,
    },
    error: {
      messageUrl: '/ais-anmeldung-fehler',
    },
  },
  texts: {
    addToCalendar: 'Veranstaltung zum Kalender hinzufügen',
    errorMessages: {
      required: 'Bitte füllen Sie dieses Pflichtfeld aus.',
      email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      letters: 'Bitte verwenden Sie nur Buchstaben.',
      numbers: 'Bitte verwenden Sie nur Zahlen.',
      fileUpload: 'Bitte laden Sie mindestes eine Datei hoch.',
    },
    fileUpload: {
      introRegistrationForm:
        'Für diese Bewerbung ist ein Antrag auf Zulassung nötig. Die Textfelder im Antrag auf Zulassung sind beschreibbar. Den Antrag können Sie hier herunterladen:',
      description: 'Datei in dieses Feld ziehen oder hier klicken',
      files: 'Bisher hochgeladene Dateien:',
      error:
        'Bei der Übertragung der Datei ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
      uploading: 'Datei wird hochgeladen...',
      maxFiles: 'Bitte nicht mehr als fünf Dateien hochladen.',
      maxSize: 'Bitte keine Datei hochladen, die größer als 20MB ist.',
    },
    notBookable: {
      headline: 'Anmeldung nicht möglich',
      text: "Leider ist die Anmeldung zur oben genannten Veranstaltung nicht möglich. Die Veranstaltung hat entweder bereits begonnen oder stattgefunden oder sie ist aus anderen Gründen nicht zur Anmeldung freigegeben (z.B. keine freien Plätze).<br /><br />Werfen Sie gerne einen Blick auf unser <strong><a href='/suche'>gesamtes Angebot</a></strong>!<br /><br />Wenn Sie Fragen haben, können Sie sich gerne bei uns telefonisch (<a href='tel:+49 30 275-82-82-12'>+49 30 275-82-82-12</a>) oder per E-Mail melden (<a href='mailto:paritaetische@akademie.org'>paritaetische@akademie.org</a>)",
    },
    newsletter: {
      description:
        'Abonnieren Sie den Newsletter der Paritätischen Akademie Berlin und Paritätischen Akademie Süd. Darin erhalten Sie regelmäßig Informationen über unsere Veranstaltungen und Weiterbildungen und erfahren direkt, wenn neue Themen in das Programm aufgenommen werden.',
    },
  },
  formTypes: [
    {
      name: 'default',
      steps: [
        {
          headline: 'Teilnehmer:in',
          buttonLabel: 'Rechnungsanschrift angeben',
          fields: ['salutation', 'firstName', 'lastName', 'email'],
          texts: {},
        },
        {
          headline: 'Rechnungsanschrift',
          buttonLabel: 'Zum nächsten Schritt',
          fields: [
            'invoiceType',
            'organizationName',
            'address',
            'zip',
            'city',
            'invoiceEmail',
          ],
          texts: {},
        },
        {
          headline: 'Verbindlich anmelden',
          buttonLabel: 'Verbindlich anmelden',
          fields: ['priceType', 'termsAndConditions', 'dataPrivacy'],
          texts: {
            noMember:
              'Eine Reduktion der Kursgebühr ist leider nicht möglich, wenn Sie sich privat anmelden.',
            enrollment:
              'Mit einem Klick melden Sie sich verbindlich für die ausgewählte Veranstaltung an. Sie erhalten umgehend eine E-Mail zur Bestätigung Ihrer Anmeldung.',
          },
        },
      ],
      formSuccess: {
        headline: 'Vielen Dank für Ihre Anmeldung',
        text: 'Wir haben Ihre Anfrage erhalten und werden Sie umgehend bearbeiten. Eine Bestätigung Ihrer Anmeldung wird Ihnen per Kürze per E-Mail zugesandt.',
      },
      formError: {
        headline: 'Hoppla, da ist etwas schief gelaufen',
        text: "Wir entschuldigen uns für die Unannehmlichkeiten und bitten Sie, es später erneut zu versuchen. Wenn das Problem dann nicht behoben sein sollte, kontaktieren Sie uns gerne unter <a href='mailto:info@akademiesued.org'>info@akademiesued.org</a>",
      },
      defaults: {
        invoiceType: 'business',
      },
    },
    {
      name: 'withDocuments',
      steps: [
        {
          headline: 'Bewerber:in',
          buttonLabel: 'Rechnungsanschrift angeben',
          fields: ['salutation', 'firstName', 'lastName', 'email'],
          texts: {},
        },
        {
          headline: 'Rechnungsanschrift',
          buttonLabel: 'Zum nächsten Schritt',
          fields: [
            'invoiceType',
            'organizationName',
            'address',
            'zip',
            'city',
            'invoiceEmail',
          ],
          texts: {},
        },
        {
          headline: 'Bewerbungsunterlagen',
          buttonLabel: 'Zum nächsten Schritt',
          fields: ['fileUploadCvAndReports', 'fileUploadApplicationAndOther'],
          texts: {},
        },
        {
          headline: 'Verbindlich bewerben',
          buttonLabel: 'Bewerbung absenden',
          fields: ['priceType', 'termsAndConditions', 'dataPrivacy'],
          texts: {
            noMember:
              'Eine Reduktion der Kursgebühr ist leider nicht möglich, wenn Sie sich privat anmelden.',
            enrollment:
              'Mit einem Klick melden Sie sich verbindlich für die ausgewählte Veranstaltung an. Sie erhalten umgehend eine E-Mail zur Bestätigung Ihrer Anmeldung.',
          },
        },
      ],
      formSuccess: {
        headline: 'Vielen Dank für Ihre Bewerbung',
        text: 'Wir haben Ihre Anfrage erhalten und werden Sie umgehend bearbeiten. Eine Bestätigung Ihrer Bewerbung wird Ihnen per Kürze per E-Mail zugesandt.',
      },
      formError: {
        headline: 'Hoppla, da ist etwas schief gelaufen',
        text: "Wir entschuldigen uns für die Unannehmlichkeiten und bitten Sie, es später erneut zu versuchen. Wenn das Problem dann nicht behoben sein sollte, kontaktieren Sie uns gerne unter <a href='mailto:info@akademiesued.org'>info@akademiesued.org</a>",
      },
      defaults: {
        invoiceType: 'business',
      },
    },
  ],
  fields: [
    {
      type: 'radio',
      typeConfig: {
        radioGroupProps: {
          row: true,
        },
      },
      name: 'salutation',
      label: 'Anrede',
      size: 12,
      show: true,
      required: true,
      variant: 'outlined',
      options: [
        {
          value: 'F',
          label: 'Frau',
        },
        {
          value: 'M',
          label: 'Herr',
        },
        {
          value: 'NON_BINARY',
          label: 'Divers',
        },
      ],
    },
    {
      type: 'text',
      name: 'firstName',
      validation: 'lettersOnly',
      size: 6,
      label: 'Vorname',
      show: true,
      required: true,
      variant: 'outlined',
      autocomplete: 'given-name',
    },
    {
      type: 'text',
      name: 'lastName',
      validation: 'lettersOnly',
      size: 6,
      label: 'Nachname',
      show: true,
      required: true,
      variant: 'outlined',
      autocomplete: 'family-name',
    },
    {
      type: 'text',
      name: 'phone',
      validation: 'numbersOnly',
      size: 6,
      label: 'Telefon-Nr.',
      show: true,
      required: true,
      variant: 'outlined',
      autocomplete: 'tel',
    },
    {
      type: 'email',
      name: 'email',
      validation: 'email',
      size: 12,
      label: 'E-Mail-Adresse',
      show: true,
      required: true,
      variant: 'outlined',
      helperText: 'Diese Adresse wird für die Seminarkommunikation genutzt',
      autocomplete: 'email',
    },
    {
      type: 'radio',
      typeConfig: {
        radioGroupProps: {
          row: true,
        },
      },
      name: 'invoiceType',
      size: 12,
      label:
        'Möchten Sie Ihre Privatadresse oder eine Firmen- oder Organisationsadresse nutzen?',
      options: [
        {
          value: 'business',
          label: 'Firmen- oder Organisationsadresse',
        },
        {
          value: 'private',
          label: 'Privatadresse',
        },
      ],
      show: true,
      required: true,
    },
    {
      type: 'text',
      name: 'organizationName',
      size: 12,
      label: 'Organisationsname',
      show: true,
      conditionWhen: 'invoiceType',
      conditionIs: 'business',
      conditionalRequired: true,
      required: true,
      variant: 'outlined',
      invoiceType: 'business',
    },
    {
      type: 'text',
      name: 'address',
      size: 12,
      label: 'Straße und Hausnummer',
      show: true,
      required: true,
      variant: 'outlined',
      invoiceType: 'both',
      autocomplete: 'street-address',
    },
    {
      type: 'text',
      name: 'zip',
      validation: 'numbersOnly',
      size: 4,
      label: 'PLZ',
      show: true,
      required: true,
      variant: 'outlined',
      invoiceType: 'both',
      autocomplete: 'postal-code',
    },
    {
      type: 'text',
      name: 'city',
      validation: 'lettersOnly',
      size: 8,
      label: 'Ort',
      show: true,
      required: true,
      variant: 'outlined',
      invoiceType: 'both',
      autocomplete: 'address-level2',
    },
    {
      type: 'email',
      name: 'invoiceEmail',
      validation: 'email',
      size: 12,
      label: 'E-Mail-Adresse',
      show: true,
      conditionWhen: 'invoiceType',
      conditionIs: 'business',
      conditionalRequired: false,
      required: false,
      variant: 'outlined',
      helperText: 'Diese Adresse wird für die Rechnungskommunikation genutzt',
      invoiceType: 'business',
      autocomplete: 'email',
    },
    {
      type: 'checkbox',
      typeConfig: {
        formGroupProps: {
          row: true,
        },
      },
      name: 'termsAndConditions',
      size: 12,
      label:
        "Ich habe die <a href=#'service/allgemeine-geschaeftsbedingungen'>AGBs</a> gelesen",
      show: true,
      showLabel: false,
      required: true,
    },
    {
      type: 'checkbox',
      typeConfig: {
        formGroupProps: {
          row: true,
        },
      },
      name: 'dataPrivacy',
      size: 12,
      label:
        "Ich habe die <a href='/service/datenschutz'>Datenschutzvereinbarung</a> gelesen",
      show: true,
      showLabel: false,
      required: true,
    },
    {
      type: 'checkbox',
      typeConfig: {
        formGroupProps: {
          row: true,
        },
      },
      name: 'invoiceUseDispatch',
      size: 12,
      conditionWhen: 'invoiceType',
      conditionIs: 'business',
      label: 'Alternative Versandadresse für Rechnungen angeben',
      show: true,
      required: false,
    },
    {
      name: 'priceType',
      type: 'priceTypeSelect',
      label: 'Preis',
      variant: 'outlined',
      size: 12,
      show: true,
    },
    {
      type: 'text',
      name: 'invoiceDispatchStreet',
      size: 12,
      label: 'Straße und Hausnummer',
      show: true,
      conditionWhen: 'invoiceDispatch',
      conditionIs: true,
      required: true,
      variant: 'outlined',
    },
    {
      type: 'text',
      name: 'invoiceDispatchPostcode',
      validation: 'numbersOnly',
      size: 4,
      label: 'PLZ',
      show: true,
      conditionWhen: 'invoiceDispatch',
      conditionIs: true,
      required: true,
      variant: 'outlined',
    },
    {
      type: 'text',
      name: 'invoiceDispatchCity',
      validation: 'lettersOnly',
      size: 8,
      label: 'Ort',
      show: true,
      conditionWhen: 'invoiceDispatch',
      conditionIs: true,
      required: true,
      variant: 'outlined',
    },
    {
      type: 'fileUpload',
      description:
        'Bitte laden Sie nun Ihren Lebenslauf und Ihr Hochschulzeugnis hoch. Falls Sie keinen Hochschulabschluss haben, laden Sie bitte Ihr Berufsausbilungszeugnis sowie einen Nachweis der Berufsausbildung hoch.',
      label: 'Lebenslauf und Zeugnisse hochladen',
      name: 'fileUploadCvAndReports',
      filesLimit: 5,
      maxSize: 20971520,
      acceptedFiles: ['.pdf', '.doc', '.docx'],
      size: 12,
      show: true,
      required: true,
    },
    {
      type: 'fileUpload',
      description:
        'Laden Sie den oben erwähnten Antrag bitte unterschrieben als Scan oder Foto wieder hoch. Falls Sie ergänzende Unterlagen haben, können Sie diese ebenfalls in diesem Schritt hochladen.',
      label: 'Anmeldeformular und sonstige Dokumente hochladen',
      name: 'fileUploadApplicationAndOther',
      filesLimit: 5,
      maxSize: 20971520,
      acceptedFiles: ['.pdf', '.doc', '.docx'],
      size: 12,
      show: true,
      required: true,
    },
    {
      type: 'email',
      name: 'prospectEmail',
      validation: 'email',
      size: 6,
      label: 'E-Mail-Adresse',
      show: true,
      required: true,
      variant: 'outlined',
      autocomplete: 'email',
    },
  ],
};

export default config;
