import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ArrowNarrowRightIcon } from '@heroicons/react/solid';
import { getImageUrlFromOffer } from '../misc/renderHelpers';
import { formatAmount } from '../misc/formatHelpers';

export default function EventCard({ offer, onClick, edmBaseUrl }) {
  if (!offer) return null;

  const { event_base, lowest_price } = offer;
  const { name, slug, event_type_name, files } = event_base;
  const eventImageUrl = files && getImageUrlFromOffer(event_base);

  return (
    <Link
      className="event-card"
      to={{ pathname: `/event/${slug}` }}
      onClick={onClick}
    >
      <div className="event-card__content">
        <div
          className="event-card__content__image"
          style={{
            backgroundImage: `url(${
              eventImageUrl
                ? `${edmBaseUrl}${eventImageUrl}`
                : 'https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80'
            })`,
          }}
        ></div>
        <div className="event-card__content__details-wrapper">
          <h3 className="group-hover:text-primary-500">{name}</h3>
          <div className="event-card__content__details">
            <div>
              <span className="event-card__content__details__name">
                {event_type_name}
              </span>
              <span className="event-card__content__details__price">
                ab {formatAmount(lowest_price)}€
              </span>
            </div>
            <ArrowNarrowRightIcon className="group-hover:translate-x-1" />
          </div>
        </div>
      </div>
    </Link>
  );
}

EventCard.propTypes = {
  offer: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  edmBaseUrl: PropTypes.string.isRequired,
};
