import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Puff } from 'svg-loaders-react';
import _isEmpty from 'lodash/isEmpty';
import { EventHeader } from '../../organisms';
import defaultConfig from '../../js/edm-registration-config';
import { eventService } from '../../api/events';
import { findLowestPrice } from '../../misc/dataHelpers';
import EdmError from '../../atoms/EdmError';
import { getImageUrlFromOffer } from '../../misc/renderHelpers';

export default function EventEnrollment({ authToken, edmConfig }) {
  const [event, setEvent] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const splittedPathname = window.location.pathname.split('/');
  const eventIdFromPathname = splittedPathname[splittedPathname.length - 1];
  const { EDM_REGISTRATION_CONFIG, EDM_BASE_URL, EDM_APP_CONFIG } =
    edmConfig || {};
  const { settings } = EDM_APP_CONFIG || {};

  window.__authToken = authToken;

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    let mounted = true;
    eventService
      .findById({
        eventId: eventIdFromPathname,
        additionalParams: { expand: 'event_base.categories' },
        onlyUpcoming: false,
      })
      .then((res) => {
        setEvent(res.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });

    // eslint-disable-next-line
    return () => (mounted = false);
  }, [eventIdFromPathname]);

  useEffect(() => {
    if (!_isEmpty(event)) {
      const finalConfig = EDM_REGISTRATION_CONFIG || defaultConfig;
      setIsLoading(false);
      window.EdmForm.init(
        finalConfig,
        event,
        'event',
        document.getElementById('edm-registration-form')
      );
    }
  }, [event]);

  return (
    <section className="py-4 flex flex-col md:flex-row md:max-w-5xl w-full mx-auto">
      {isError && <EdmError />}

      {isLoading && (
        <div className="flex max-w-3xl lg:max-w-4xl mx-auto">
          <div className="flex items-center justify-center w-full">
            <Puff stroke="#000" className="mx-auto w-20 h-20" />
          </div>
        </div>
      )}

      {!isLoading && !_isEmpty(event) && (
        <EventHeader
          attendeesMax={event.attendees_max}
          slug={event.event_base.slug}
          edmBaseUrl={EDM_BASE_URL}
          eventId={event.id}
          eventIdNumber={event.id_number}
          eventTypeName={event.event_base.event_type_name}
          eventImageUrl={getImageUrlFromOffer(event.event_base)}
          name={event.event_base.name}
          summary={event.event_base.summary}
          categories={event.event_base.categories}
          location={event.location}
          dates={event.dates}
          price={findLowestPrice(event.prices)}
          validBookings={event.meta.all_valid_bookings_count}
          settings={settings}
        />
      )}
      <div className="flex md:pt-10" id="edm-registration-form"></div>
    </section>
  );
}

EventEnrollment.propTypes = {
  authToken: PropTypes.string,
  edmConfig: PropTypes.object.isRequired,
};
