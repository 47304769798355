import React, { forwardRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { de } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarIcon } from '@heroicons/react/outline';
import { CheckIcon } from '@heroicons/react/solid';
import { dayMachineFormat } from '../../misc/formatHelpers';

export default function FilterDate({
  addFilter,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {
  const onChangeStart = (date) => {
    addFilter('date-start', dayMachineFormat(date));
    setStartDate(date);
  };

  const onChangeEnd = (date) => {
    addFilter('date-end', dayMachineFormat(date));
    setEndDate(date);
  };

  const CustomInput = forwardRef(
    ({ placeholderText, value, onClick, type, disabled }, ref) => (
      <div>
        <button
          className={`${
            value
              ? 'bg-primary-500 text-gray-50 border-transparent'
              : 'border-gray-300 bg-white'
          } relative group w-full py-2 pl-3 pr-10 text-left rounded-md shadow-sm border transition duration-300 ease-out focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed`}
          onClick={onClick}
          ref={ref}
          disabled={disabled}
        >
          <span className="block truncate font-medium">
            {value
              ? type === 'start'
                ? `ab ${value}`
                : `bis ${value}`
              : placeholderText}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            {value ? (
              <CheckIcon
                className="w-5 h-5 ml-2 text-gray-50"
                aria-hidden="true"
              />
            ) : (
              <CalendarIcon
                className={`${
                  !disabled && 'group-hover:opacity-100'
                } w-5 h-5 ml-2 opacity-50 text-gray-500`}
                aria-hidden="true"
              />
            )}
          </span>
        </button>
      </div>
    )
  );

  CustomInput.propTypes = {
    placeholderText: PropTypes.string,
    value: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  };

  return (
    <Fragment>
      <DatePicker
        selected={startDate}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        minDate={new Date()}
        onChange={onChangeStart}
        customInput={<CustomInput type="start" placeholderText="ab heute" />}
        showPopperArrow={false}
        locale={de}
      />
      <DatePicker
        selected={endDate}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate || new Date()}
        disabled={!startDate}
        onChange={onChangeEnd}
        customInput={<CustomInput type="end" placeholderText="kein Enddatum" />}
        showPopperArrow={false}
        locale={de}
      />
    </Fragment>
  );
}

FilterDate.propTypes = {
  addFilter: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
};
