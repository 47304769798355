import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/solid';

export default function EventAvailability({ attendeesMax, validBookings }) {
  const availability = attendeesMax - validBookings;

  if (typeof availability === 'undefined' || !attendeesMax) return null;

  const isFull = availability <= 0;
  const isAlmostFull =
    !isFull && availability / attendeesMax < 0.2 && attendeesMax !== null;

  return (
    <div className="flex items-center">
      {isFull && (
        <Fragment>
          <XCircleIcon className="h-5 w-5 text-red-500" />
          <span className="ml-1 text-sm text-red-600">
            Keine Plätze mehr verfügbar
          </span>
        </Fragment>
      )}
      {isAlmostFull && (
        <Fragment>
          <ExclamationCircleIcon className="h-5 w-5 text-yellow-500" />
          <span className="ml-1 text-sm text-yellow-600">
            Nur noch {availability} Plätze verfügbar
          </span>
        </Fragment>
      )}
      {!isFull && !isAlmostFull && (
        <Fragment>
          <CheckCircleIcon className="h-5 w-5 text-green-500" />
          <span className="ml-1 text-sm text-green-600">
            Noch {availability} Plätze verfügbar
          </span>
        </Fragment>
      )}
    </div>
  );
}

EventAvailability.propTypes = {
  attendeesMax: PropTypes.number,
  validBookings: PropTypes.number.isRequired,
};
