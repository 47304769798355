import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Puff } from 'svg-loaders-react';
import { locationService } from '../api/locations';
import EdmError from '../atoms/EdmError';
import List from '../organisms/List';

export default function LocationList({ edmConfig }) {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const { EDM_BASE_URL } = edmConfig;

  useEffect(() => {
    setIsError(false);
    setIsLoading(true);

    locationService
      .findAll()
      .then((res) => {
        setIsLoading(false);
        setLocations(res.data.results);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  return (
    <section className="py-4">
      <div className="flex flex-wrap max-w-3xl lg:max-w-4xl mx-auto prose lg:prose-xl prose-primary">
        {isError && <EdmError />}

        {isLoading && (
          <div className="flex items-center justify-center w-full">
            <Puff stroke="#000" className="mx-auto w-20 h-20" />
          </div>
        )}

        {!isLoading && locations && (
          <List locations={locations} edmBaseUrl={EDM_BASE_URL} />
        )}
      </div>
    </section>
  );
}

LocationList.propTypes = {
  edmConfig: PropTypes.object.isRequired,
};
