import React from 'react';
import PropTypes from 'prop-types';
import { CalendarIcon } from '@heroicons/react/outline';
import { dayGermanFormat, formatDateSpan } from '../misc/formatHelpers';
import IconLabel from './IconLabel';

export default function EventDate({
  events,
  dates,
  firstDay,
  color,
  size,
  direction,
  fontWeight,
}) {
  if (!dates && !events) return null;

  const numberOfDates = events && events[0].dates.length;
  const icon = <CalendarIcon />;
  const label = dates
    ? formatDateSpan(dates)
    : `${events.length > 1 || numberOfDates > 1 ? 'ab' : ''} ${dayGermanFormat(
        firstDay
      )}${numberOfDates > 1 ? ` (${numberOfDates} Termine)` : ''}`;

  return (
    <IconLabel
      label={label}
      icon={icon}
      color={color}
      size={size}
      direction={direction}
      fontWeight={fontWeight}
    />
  );
}

EventDate.propTypes = {
  events: PropTypes.array,
  dates: PropTypes.array,
  firstDay: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  direction: PropTypes.string,
  fontWeight: PropTypes.string,
};
