import React, { Fragment, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BanIcon } from '@heroicons/react/outline';
import { getAuthToken } from './api/auth';
import EventList from './pages/EventList';
import EventDetail from './pages/EventDetail';
import EventEnrollment from './pages/EventEnrollment';
import LecturerDetail from './pages/LecturerDetail';
import LecturerList from './pages/LecturerList';
import LocationDetail from './pages/LocationDetail';
import LocationList from './pages/LocationList';
import Error404 from './pages/Error404';
import EdmError from './atoms/EdmError';
import {
  applyBaseTheme,
  getColorsFromConfigAndApplyTheme,
  getFontFromConfigAndApplyTheme,
} from './misc/theming';
import { objectIsEmpty } from './misc/miscHelpers';
import Error from './atoms/Error';

export default function App({ edmConfig, hasEdmConfiguration }) {
  const existingAuthToken = sessionStorage.getItem('oauth');
  const [isError, setIsError] = useState(false);
  const [authToken, setAuthToken] = useState(existingAuthToken);

  const configurationErrorIcon = <BanIcon className="text-red-400 w-16 h-16" />;
  const configurationErrorHeadline = 'Konfigurationsdatei fehlt!';
  const configurationErrorText = (
    <p>
      Ohne Konfigurationsdatei im JSON-Format können wir leider keine Verbindung
      zu Deiner EDM-Instanz herstellen. Bitte prüfe daher, ob die Konfiguration
      korrekt weitergegeben wird oder überhaupt vorhanden ist.
    </p>
  );

  const setToken = (token) => {
    setAuthToken(token);
    sessionStorage.setItem('oauth', token);
  };

  useEffect(() => {
    const { EDM_APP_CONFIG } = edmConfig || {};
    const { colors, fontFamily } = EDM_APP_CONFIG || {};
    const cssVarPrefix = '--theme';

    applyBaseTheme();

    if (!objectIsEmpty(colors)) {
      getColorsFromConfigAndApplyTheme(cssVarPrefix, colors);
    }

    if (!objectIsEmpty(fontFamily)) {
      getFontFromConfigAndApplyTheme(cssVarPrefix, fontFamily);
    }
  }, []);

  useEffect(() => {
    setIsError(false);
    if (!authToken) {
      if (edmConfig) {
        getAuthToken({
          client_id: edmConfig.OAUTH2_CLIENT_ID,
          client_secret: edmConfig.OAUTH2_CLIENT_SECRET,
        })
          .then((res) => {
            const token = res.data.access_token;
            setToken(token);
          })
          .catch(() => {
            setIsError(true);
          });
      }
    }
  }, []);

  return (
    <Fragment>
      {!hasEdmConfiguration && (
        <Error
          icon={configurationErrorIcon}
          headline={configurationErrorHeadline}
          text={configurationErrorText}
        />
      )}

      {hasEdmConfiguration && isError && <EdmError />}

      {hasEdmConfiguration && authToken && (
        <Fragment>
          <Routes>
            <Route
              path="/events"
              element={<EventList edmConfig={edmConfig} />}
            />
            <Route
              path="/event/:slug"
              element={<EventDetail edmConfig={edmConfig} />}
            />
            <Route
              path="/enrollment/:id"
              element={
                <EventEnrollment authToken={authToken} edmConfig={edmConfig} />
              }
            />
            <Route
              path="/lecturers"
              element={<LecturerList edmConfig={edmConfig} />}
            />
            <Route
              path="/lecturer/:id"
              element={<LecturerDetail edmConfig={edmConfig} />}
            />
            <Route
              path="/locations"
              element={<LocationList edmConfig={edmConfig} />}
            />
            <Route
              path="/location/:id"
              element={<LocationDetail edmConfig={edmConfig} />}
            />
            <Route path="*" element={Error404} />
          </Routes>
        </Fragment>
      )}
    </Fragment>
  );
}

App.propTypes = {
  edmConfig: PropTypes.object.isRequired,
  hasEdmConfiguration: PropTypes.bool.isRequired,
};
