import axios from 'axios';
import { concatenateExpandParam } from '../misc/formatHelpers';

const defaultListParams = {
  ordering: 'last_name',
};

const defaultParams = {
  expand: '~all',
};

const findAll = () =>
  axios.request({
    url: 'lecturers/',
    method: 'get',
    params: {
      ...defaultListParams,
    },
  });

const findById = ({ id, additionalParams }) =>
  axios.request({
    url: `lecturers/${id}/`,
    method: 'get',
    params: {
      ...defaultParams,
      ...additionalParams,
      expand: concatenateExpandParam(
        defaultParams.expand,
        additionalParams && additionalParams.expand
      ),
    },
  });

export const lecturerService = {
  findAll,
  findById,
};
