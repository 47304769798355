import React from 'react';
import PropTypes from 'prop-types';
import EventLocation from '../../../atoms/EventLocation';
import EventLecturer from '../../../atoms/EventLecturer';
import EventPrice from '../../../atoms/EventPrice';
import LinkedButton from '../../../atoms/LinkedButton';
import { formatDateSpan } from '../../../misc/formatHelpers';
import EventDates from './EventDates';

export default function EventAlternatives({
  label,
  events,
  switchActiveEvent,
  eventAlternativesRef,
}) {
  return (
    <div className="py-2" id="event-alternatives" ref={eventAlternativesRef}>
      <h2 className="font-heading text-gray-900 tracking-wider uppercase">
        {label}
      </h2>
      <div className="event-alternatives">
        {events.map((event, index) => (
          <div key={index} className="event-alternatives__event">
            <div className="event-alternatives__event__headline">
              <span onClick={() => switchActiveEvent(event.id)}>
                {formatDateSpan(event.dates)}
              </span>
            </div>
            <div className="event-alternatives__event__details">
              <div className="event-alternatives__event__details__item">
                <EventLocation
                  location={event.location}
                  color="primary"
                  size="md"
                  direction="row"
                  fontWeight="medium"
                />
              </div>
              <div
                className={`${
                  event.dates.length > 1 ? 'items-start' : 'items-center'
                } flex`}
              >
                <EventDates
                  dates={event.dates}
                  listDates
                  color="primary"
                  size="md"
                  direction="row"
                  fontWeight="medium"
                />
              </div>
              <div className="event-alternatives__event__details__item">
                <EventPrice
                  lowestPrice={event.lowest_price}
                  color="primary"
                  size="md"
                  direction="row"
                  fontWeight="medium"
                />
              </div>
              <div className="event-alternatives__event__details__item">
                <EventLecturer
                  lecturers={event.lecturers}
                  color="primary"
                  size="md"
                  direction="row"
                  fontWeight="medium"
                />
              </div>
            </div>
            <div className="event-alternatives__event__button">
              <div>
                <LinkedButton
                  to={{ pathname: `/enrollment/${event.id}` }}
                  label="Anmelden"
                  variant="solid"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

EventAlternatives.propTypes = {
  label: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  switchActiveEvent: PropTypes.func.isRequired,
  eventAlternativesRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};
