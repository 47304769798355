import React from 'react';
import PropTypes from 'prop-types';
import { dayGermanFormat } from '../../../misc/formatHelpers';

export default function EventDates({ dates }) {
  return (
    <ul>
      {dates.map((date, index) => (
        <li key={index}>
          <span className="font-medium text-gray-900">{`${dayGermanFormat(
            date.day
          )}: `}</span>
          {`${date.start_time} - ${date.end_time} Uhr`}
        </li>
      ))}
    </ul>
  );
}

EventDates.propTypes = {
  dates: PropTypes.array.isRequired,
};
