import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ArrowNarrowUpIcon, ArrowNarrowDownIcon } from '@heroicons/react/solid';
import Chip from '../../atoms/Chip';

export default function SortItem({ sortedBy, setSortedBy, sortType }) {
  const { type, order } = sortedBy;
  const handleClick = () => {
    if (type !== sortType) {
      setSortedBy({
        type: sortType,
        order: 'ascending',
      });
    } else if (order === 'ascending') {
      setSortedBy({
        type: sortType,
        order: 'descending',
      });
    } else if (order === 'descending') {
      setSortedBy({ type: 'date', order: 'ascending' });
    }

    return null;
  };

  const getSortLabel = () => {
    switch (sortType) {
      case 'date':
        return 'Datum';
      case 'eventBaseName':
        return 'Veranstaltungstitel';
      default:
        return null;
    }
  };

  const sortIcon =
    sortType === type && order === 'ascending' ? (
      <ArrowNarrowUpIcon className="w-4 h-4 text-gray-500 ml-1 group-hover:text-gray-700 cursor-pointer" />
    ) : (
      sortType === type &&
      order === 'descending' && (
        <ArrowNarrowDownIcon className="w-4 h-4 text-gray-500 ml-1 group-hover:text-gray-700 cursor-pointer" />
      )
    );
  return (
    <Fragment>
      <Chip
        onClick={() => handleClick()}
        label={getSortLabel()}
        color="gray"
        icon={sortIcon}
      />
    </Fragment>
  );
}

SortItem.propTypes = {
  sortedBy: PropTypes.object,
  setSortedBy: PropTypes.func.isRequired,
  sortType: PropTypes.string,
};
