import queryString from 'query-string';
import { objectIsEmpty } from './miscHelpers';

export function applyFiltersFromQueryParams({
  setCurrentFilters,
  currentFilters,
}) {
  const queryParams = queryString.parse(window.location.search, {
    arrayFormat: 'bracket-separator',
  });
  const isFilteredWithQueryParams = queryParams.filter.length > 0;

  if (isFilteredWithQueryParams) {
    const filterQueryParams = queryParams.filter;
    const filterSeparator = '=';

    filterQueryParams.forEach((filter) => {
      const filterType = filter.split(filterSeparator)[0];
      const filterValue = filter.split(filterSeparator)[1];

      setCurrentFilters([
        ...currentFilters.filter((item) => item.type !== filterType),
        { type: filterType, id: filterValue },
      ]);
    });
  }
}

export function getImageUrlFromOffer(offer) {
  let imageUrl = '';
  const { image } = offer || {};

  if (!objectIsEmpty(image)) {
    imageUrl = image.url;
  }

  return imageUrl;
}

export const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;
