import React, { Fragment, cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function IconLabel({
  icon,
  size,
  color,
  direction,
  label,
  fontWeight,
}) {
  const iconClasses = classnames('flex-shrink-0', {
    'h-5 w-5': !size || size === 'sm',
    'h-7 w-7': size === 'md',
    'h-7 w-7 sm:h-10 sm:w-10': size === 'lg',
    'text-primary-500': !color || color === 'primary',
    'text-gray-50': color === 'light',
  });

  const labelClasses = classnames({
    'font-normal': !fontWeight || fontWeight === 'normal',
    'font-medium': fontWeight === 'medium',
    'font-semibold': fontWeight === 'semibold',
    'text-sm': !size || size === 'sm',
    'text-base': size === 'md',
    'text-md': size === 'lg',
    'ml-2': !direction || direction === 'row',
    'text-gray-900': !color || color === 'primary',
    'text-gray-50': color === 'light',
    'mt-0 sm:mt-4 ml-4 sm:ml-0 flex flex-col items-center': direction === 'col',
  });

  return (
    <Fragment>
      {cloneElement(Children.only(icon), { className: iconClasses })}
      <span className={labelClasses}>{label}</span>
    </Fragment>
  );
}

IconLabel.propTypes = {
  icon: PropTypes.object,
  size: PropTypes.string,
  color: PropTypes.string,
  direction: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  fontWeight: PropTypes.string,
};
