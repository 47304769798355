import React from 'react';
import PropTypes from 'prop-types';
import LinkedButton from '../../atoms/LinkedButton';
import { stripHtmlTags } from '../../misc/formatHelpers';
import ButtonArrow from '../../atoms/ButtonArrow';

export default function ListItem({
  index,
  id,
  image,
  label,
  description,
  linkPath,
  onClick,
  edmBaseUrl,
}) {
  const pathname = `/${linkPath}/${id}`;
  const icon = <ButtonArrow />;

  return (
    <div
      className={`${index === 0 ? 'pb-8' : 'py-8'} flex flex-col sm:flex-row`}
    >
      {image && (
        <div
          className="flex-none bg-cover mb-4 sm:mb-0 w-52 h-52 max-h-52 rounded shadow"
          style={{
            backgroundImage: `url(${edmBaseUrl}${image.url})`,
          }}
        ></div>
      )}
      <div className={`${image ? 'sm:ml-8' : ''} flex flex-col flex-shrink`}>
        <span className="mb-4 text-gray-900 font-medium text-2xl">{label}</span>
        {description && (
          <span className="mb-4">{stripHtmlTags(description)}</span>
        )}
        <LinkedButton
          to={{ pathname }}
          onClick={onClick}
          label="Veranstaltungen anzeigen"
          variant="light"
          icon={icon}
          size="small"
        />
      </div>
    </div>
  );
}

ListItem.propTypes = {
  index: PropTypes.number,
  id: PropTypes.number,
  image: PropTypes.object,
  label: PropTypes.string,
  description: PropTypes.string,
  linkPath: PropTypes.string,
  onClick: PropTypes.func,
  edmBaseUrl: PropTypes.string.isRequired,
};
