import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function Button({
  label,
  customRef,
  variant,
  fullWidth,
  icon,
  size,
  onClick,
}) {
  const buttonClasses = classnames(
    'flex group items-center justify-center font-semibold border-2 border-transparent rounded-md no-underline transition ease-in-out duration-300 focus:outline-none ',
    {
      'h-14 px-6 py-2 md:text-lg md:px-8': !size || size === 'medium',
      'h-10 px-4 py-2 text-base': size === 'small',
      'shadow text-gray-50 bg-primary-500 hover:bg-primary-600':
        !variant || variant === 'solid',
      'shadow text-gray-50 bg-secondary-500 hover:bg-secondary-600':
        variant === 'solid-secondary',
      'text-primary-500 bg-gray-50 shadow hover:bg-primary-50':
        variant === 'inverted',
      'text-primary-500 border-2 border-primary-500 bg-transparent shadow-none hover:text-primary-600 hover:border-primary-600 hover:bg-primary-50':
        variant === 'outlined',
      'text-primary-500 bg-primary-50 hover:bg-primary-100 shadow-none':
        variant === 'light',
      'shadow text-purple-600 bg-gray-50 hover:bg-gray-200':
        variant === 'banner',
      'w-full': fullWidth,
    }
  );

  return (
    <button className={buttonClasses} ref={customRef} onClick={onClick}>
      {label}
      {icon}
    </button>
  );
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  customRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  variant: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
  onClick: PropTypes.func,
};
