import _groupBy from 'lodash/groupBy';
import _values from 'lodash/values';
import { dayMachineFormat } from './formatHelpers';

function removeDuplicatesById(array) {
  const reducedArray = array.reduce((acc, current) => {
    const x = acc.find((item) => item.id === current.id);
    if (!x) {
      return acc.concat([current]);
    }
    return acc;
  }, []);

  return reducedArray;
}

export function findLowestPrice(prices) {
  const priceAmounts = [];
  prices.forEach((price) => priceAmounts.push(price.amount));

  const sortedPrices = priceAmounts.sort((a, b) => {
    return a - b;
  });

  return sortedPrices[0];
}

export function sanitizeEvents(events) {
  const sanitizedEvents = [];
  const today = dayMachineFormat(Date.now());

  // only add events that have price information and that have not started yet
  events.forEach((event) => {
    if (event.first_day > today && event.prices.length > 0) {
      sanitizedEvents.push({
        ...event,
        lowest_price: findLowestPrice(event.prices),
      });
    }
  });

  return sanitizedEvents;
}

export function getEventsGroupedByOffer(events) {
  const eventsGroupedByOffer = _groupBy(events, (event) => event.event_base.id);
  const eventsGroupedByOfferArray = _values(eventsGroupedByOffer);
  const finalOffers = [];

  eventsGroupedByOfferArray.forEach((offer) => {
    const finalEvents = [];
    const locations = [];
    const first_days = [];
    const prices = [];
    let event_base = {};

    offer.forEach((event) => {
      finalEvents.push(event);
      if (event.location) locations.push(event.location);
      first_days.push(event.first_day);
      event.prices.forEach((price) => prices.push(price.amount));
      event_base = event.event_base;
    });

    prices.sort((a, b) => {
      return a - b;
    });

    const finalOffer = {
      event_base,
      events: finalEvents,
      locations: removeDuplicatesById(locations),
      first_day: first_days[0],
      lowest_price: prices[0],
    };

    finalOffers.push(finalOffer);
  });

  return finalOffers.sort((a, b) => a.first_day.localeCompare(b.first_day));
}

export function prepareFilterData(offers) {
  const categoryData = [{ name: 'Alle Themen' }];
  const formatData = [{ name: 'Alle Formate' }];
  const locationData = [{ name: 'Alle Orte' }];

  offers.forEach((offer) => {
    const { event_base, locations } = offer;
    const { categories, event_type, event_type_name } = event_base;

    // Fill Categories
    categories.forEach((category) => {
      if (category) {
        categoryData.push({
          name: category.name.trim(),
          id: category.id,
        });
      }
    });

    // Fill Formats
    formatData.push({
      name: event_type_name.trim(),
      id: event_type,
    });

    // Fill Locations
    locations.forEach((location) => {
      if (location) {
        locationData.push({
          name: location.name.trim(),
          id: location.id,
        });
      }
    });
  });

  const filterData = {
    categories: {
      labelSingular: 'Thema',
      labelPlural: 'Themen',
      data: removeDuplicatesById(categoryData).sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    },
    event_types: {
      labelSingular: 'Format',
      labelPlural: 'Formate',
      data: removeDuplicatesById(formatData).sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    },
    locations: {
      labelSingular: 'Ort',
      labelPlural: 'Orte',
      data: removeDuplicatesById(locationData).sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    },
  };

  return filterData;
}
