import queryString from 'query-string';
import axios from 'axios';

export const getAuthToken = ({ client_id, client_secret }) =>
  axios.request({
    url: 'o/token/',
    method: 'post',
    data: queryString.stringify({
      grant_type: 'client_credentials',
      client_id,
      client_secret,
    }),
  });
