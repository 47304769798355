import React from 'react';
import PropTypes from 'prop-types';
import ListItem from './ListItem';

export default function List({ edmBaseUrl, lecturers, locations }) {
  const onClickLecturer = () =>
    window.parent.postMessage('edm|navigatedToLecturer', '*');
  const onClickLocation = () =>
    window.parent.postMessage('edm|navigatedToLocation', '*');

  return (
    <div className="divide-y w-full">
      {lecturers &&
        lecturers.map((lecturer, index) => {
          const { id, image, title, first_name, last_name, description } =
            lecturer;

          const label = title
            ? `${title} ${first_name} ${last_name}`
            : `${first_name} ${last_name}`;

          return (
            <ListItem
              key={index}
              index={index}
              id={id}
              image={image}
              label={label}
              description={description}
              linkPath="lecturer"
              onClick={onClickLecturer}
              edmBaseUrl={edmBaseUrl}
            />
          );
        })}

      {locations &&
        locations.map((location, index) => {
          const { id, image, name, address } = location;
          const { address_line_1, address_line_2, postcode, city } =
            address || {};

          const description = address_line_2
            ? `${address_line_1} · ${address_line_2} · ${postcode} ${city}`
            : `${address_line_1} · ${postcode} ${city}`;

          return (
            <ListItem
              key={index}
              id={id}
              image={image}
              label={name}
              description={address ? description : null}
              linkPath="location"
              onClick={onClickLocation}
              edmBaseUrl={edmBaseUrl}
            />
          );
        })}
    </div>
  );
}

List.propTypes = {
  edmBaseUrl: PropTypes.string.isRequired,
  lecturers: PropTypes.array,
  locations: PropTypes.array,
};
